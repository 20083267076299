

var default_timepicker_values = {
  timeFormat: 'HH:mm',
  interval: 15,
  minTime: '0',
  maxTime: '23:59',
  startTime: '0:00',
  dynamic: false,
  dropdown: true,
  scrollbar: true      
}

$( document ).on('turbolinks:load', function(){
  
  init_callbacks($("form"));
  
  // Add fields tag behavior
  $('form').on('click', '[data-behavior="add_fields_tag"]', function(event) {
    var new_elem = $(this).parents(".card").find(".card-body");
    new_elem.append(($(this).data('fields')));
    init_callbacks(new_elem.find(".form-group").last());
    return event.preventDefault();
  });
  
  $('.q-reset').click(function(evnt){
    $(this).parents("form").find("input").val('')
    $(this).parents("form").find("select").val('')
    $(this).parents("form").find("textarea").val('')
  });

  $('form').on('click', '[data-behaviour="remove-image"]', function(event) {
    event.preventDefault();
    $(this).prev('input[type="hidden"]').val('true');
    $(this).parent().find('img').remove();
    $(this).remove();
  });
  
  $('#select2users').select2({
    ajax: {
      url: '/bo/admin/search_accounts',
      type: 'GET',
      dataType: 'json',
      data: function(params){
        var queryParameters = {
          term: params.term
        }
        return queryParameters;        
      },
      processResults: function (data){
        return{
          results: $.map(data, function(item){
            return{ text: item[0], id: item[1]};
          })
        };
      }
    }
  });
});

function init_callbacks(elem){
  
  // TimePicker
  elem.find("[data-behavior='timepicker']").timepicker(default_timepicker_values);

  // DatePicker
  elem.find("[data-behavior='datepicker']").daterangepicker({
    singleDatePicker: true,
    autoUpdateInput: false,
    showDropdowns: true,
    minYear: 1901,
    locale: {
      format: 'DD-MM-YYYY'
    }
  }, function(start, end, label) {
    $(this.element).val(start.format('DD-MM-YYYY'));
  });
  
  // Remove fields tag behavior
  elem.on('click', '[data-behavior="remove_fields_tag"]', function(event) {
    $(this).parents(".form-group").remove()
    return event.preventDefault();
  });
}
